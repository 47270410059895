import { useEffect } from "react";
import skipifyEvents from "@services/skipifyEvents";

/**
 * Hook to handle parent window event tracking, the event is sent from the parent window via SDK.
 * @param allowedOrigins Array of allowed origins for postMessage events
 * @returns void
 */
export const useParentWindowEventTracking = (allowedOrigins: string[]) => {
  useEffect(() => {
    if (!window || !allowedOrigins.length) {
      return;
    }

    function messageListener(ev: MessageEvent) {
      if (!allowedOrigins.includes("*") && !allowedOrigins.includes(ev.origin)) {
        return;
      }

      const { name, payload } = ev.data;

      if (name === "@skipify/track-event") {
        skipifyEvents.track(payload.type, payload.event_properties);
      }
    }

    window.addEventListener("message", messageListener);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, [allowedOrigins]);
};
