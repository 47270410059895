import useStepupHandlerStore from "@stores/stepupHandlerStore";
import { FunctionComponent, useEffect } from "react";
import ExternalContent from "./ExternalContent";
import { useMediaQuery, useTheme } from "@mui/material";
import skipifyEvents from "@services/skipifyEvents";
import getEnv from "@utils/getEnv";
import { isDesktop } from "react-device-detect";
import useSkipifyLayer from "@hooks/useSkipifyLayer";
import useParentWindowComms, { LISTENER_IDS } from "@hooks/useParentWindowComms";
import useMerchantConfigStore from "@stores/merchantConfigStore";
import { usePathname } from "next/navigation";

/**
 * Checks if the given input is a valid URL.
 *
 * @param url A URL object or a string that is potentially a URL.
 * @returns Valid status.
 */
function isValidUrl(url: string | URL): boolean {
  if (url instanceof URL) {
    return true;
  }

  try {
    new URL(url);
  } catch (_) {
    return false;
  }

  return true;
}

/**
 * Logic for displaying and hiding the third party stepup. Adds in a listener for the callback to set the success state and close the iframe.
 */
const StepupListener: FunctionComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const skipifyLayer = useSkipifyLayer();
  const pathname = usePathname();
  const { iframeHidden, setIframeHidden, setSuccess, setStepupComplete, stepupSrc } = useStepupHandlerStore();
  const allowedDomains = useMerchantConfigStore((state) => state.allowedDomains);
  const { resizeContainer } = useParentWindowComms({ allowedDomains });
  const isPaylink = pathname?.includes("paylink");

  useEffect(() => {
    if (!window) {
      return;
    }

    /**
     * Listens for a close iframe event from the Skipify stepup callback site.
     *
     * @param ev Message event from any source.
     */
    function messageListener(ev: MessageEvent) {
      if (ev.origin !== getEnv().STEPUP_CALLBACK_ORIGIN) {
        return;
      }

      const { name, payload } = ev.data;

      if (name !== "@skipify/close-iframe") {
        return;
      }

      const { success } = payload;

      setIframeHidden(true);
      setSuccess(success);
      setStepupComplete(true);
      success
        ? skipifyEvents.track("fe_cl_issuer_challenge_success")
        : skipifyEvents.track("fe_cl_issuer_challenge_fail");
    }

    window.addEventListener("message", messageListener);

    if (getEnv().DEBUG === "true") {
      console.log("Stepup callback listener registered on window");
    }

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, [setIframeHidden, setSuccess, setStepupComplete]);

  const canShow = isValidUrl(stepupSrc);
  const visible = canShow && !iframeHidden;
  const maxWidth = isMobile ? false : "xs";

  const calculateHeight = () => {
    if (skipifyLayer || (isPaylink && isDesktop)) return 399; // Height for v2 (skipify layer) or Paylink
    return isDesktop ? 381 : 396; // Height for v1 (desktop or mobile)
  };

  useEffect(() => {
    const externalHeight = calculateHeight();
    if (window?.innerHeight < externalHeight && visible) {
      // If the height of our iframe is smaller than the height we will use for the external iframe, we need to resize our iframe
      resizeContainer(
        (externalHeight + 25).toString(),
        pathname?.match(/\/components\/.+\/carousel/) ? LISTENER_IDS.CAROUSEL_COMPONENT : undefined,
      ); // add an extra 25 px so it still looks like an overlay and not taking up the enitre screen
    }
  }, [calculateHeight, pathname, resizeContainer, visible]);

  return (
    <ExternalContent
      hideBackdrop={pathname?.match(/\/components\/.+\/carousel/) !== null}
      hidden={!visible}
      src={stepupSrc.toString()}
      maxWidth={maxWidth}
      width="100%"
      height={calculateHeight()}
      onClose={() => {
        setSuccess(false);
        setStepupComplete(true);
        setIframeHidden(true);
        skipifyEvents.track("fe_cl_issuer_challenge_exited");
        skipifyEvents.setIssuer(undefined);
      }}
    />
  );
};

export default StepupListener;
