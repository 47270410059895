import {
  MerchantBranding,
  MerchantCart,
  MerchantFees,
  MerchantSplitPaymentsConfig,
  MerchantTerms,
  MerchantUrls,
  SupportedCountry,
  ClickToPayConfigs,
} from "@models/merchantConfig";
import skipifyEvents from "@services/skipifyEvents";
import { getMerchantConfig } from "@services/merchantConfig";
import { inDevTestLocal } from "@utils/inDevTestLocal";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export type MerchantConfigStore = {
  // True if we should skip shipping and delivery section
  shippingDeliveryNotRequired: boolean;
  merchantId?: string;
  branding: Partial<MerchantBranding>;
  allowedDomains: MerchantUrls;
  loading: boolean;
  industry?: string;
  parentMerchantName?: string;
  freeShippingLabel?: string;
  feeConfigs?: MerchantFees;
  termsConfigs?: MerchantTerms;
  termsCheckbox?: boolean;
  cartConfigs: MerchantCart;
  topLevelMerchantId?: string;
  splitPaymentsConfig?: MerchantSplitPaymentsConfig;
  supportedCountries?: SupportedCountry[];
  clickToPayConfigs?: ClickToPayConfigs;
  fetch(merchantId: string): Promise<void>;
};

const useMerchantConfigStore = create<MerchantConfigStore>()(
  devtools(
    (set, get) => ({
      loading: false,
      shippingDeliveryNotRequired: false,
      branding: {},
      allowedDomains: [],
      industry: undefined,
      freeShippingLabel: undefined,
      parentMerchantName: undefined,
      cartConfigs: {
        DISALLOW_CART_EDITS: {
          enabled: true,
        },
        DISCOUNTS: {
          enabled: false,
        },
      },
      async fetch(merchantId: string) {
        if (get().merchantId === merchantId) {
          return;
        }
        set({ loading: true });
        try {
          const {
            branding,
            urls,
            merchantId: id,
            noShippingRequired,
            freeShippingLabel,
            parentMerchantName,
            topLevelMerchantId,
            industry,
            feeConfigs,
            termsConfigs,
            termsCheckbox,
            cartConfigs,
            splitPaymentsConfig,
            supportedCountries,
            clickToPayConfigs,
          } = await getMerchantConfig(merchantId);
          skipifyEvents.setMerchantProperties(id, branding.displayName, industry);
          skipifyEvents.identify(undefined, {
            parent_merchant_name: parentMerchantName,
            merchant_id: merchantId,
            merchant_name: branding.displayName,
          });
          set(
            {
              merchantId: id,
              branding,
              allowedDomains: urls,
              shippingDeliveryNotRequired: noShippingRequired,
              freeShippingLabel,
              parentMerchantName,
              topLevelMerchantId,
              industry,
              feeConfigs,
              termsConfigs,
              termsCheckbox,
              cartConfigs,
              splitPaymentsConfig,
              supportedCountries: supportedCountries?.toSorted((a, b) => {
                if (a.countryCode === "US") return -1;
                if (b.countryCode === "US") return 1;
                return a.countryName.localeCompare(b.countryName);
              }),
              clickToPayConfigs,
            },
            undefined,
            "SET_MERCHANT_PUBLIC_INFO",
          );
        } finally {
          set({ loading: false });
        }
      },
    }),
    { enabled: inDevTestLocal, name: "merchantConfigStore" },
  ),
);

export default useMerchantConfigStore;
