import { PaletteOptions } from "@mui/material/styles/createPalette";

const palette: PaletteOptions = {
  primary: {
    light: "#E0E0E0",
    main: "#757575",
    dark: "#1E1E1E",
  },
  secondary: {
    light: "#F3F3F3",
    main: "#C4C4C4",
    dark: "#747474",
  },
  brand: {
    light: "#E2F7F2",
    main: "#01EAD3",
    dark: "#3EDBCC",
  },
  error: {
    light: "#FEF3F2",
    main: "#F04438",
    dark: "#BC3003",
  },
  warning: {
    light: "#FFFAEB",
    main: "#F79009",
    dark: "#AC5F04",
  },
  success: {
    light: "#ECFDF3",
    main: "#12B76A",
    dark: "#207A00",
  },
  link: {
    main: "#1F7F79",
  },
  background: {
    default: "#FEFEFE",
    light: "#FEFEFE",
    dark: "#1E1E1E",
    info: "#F1FBF9",
  },
  text: {
    primary: "#1E1E1E",
    secondary: "#757575",
    disabled: "#E0E0E0",
    white: "#FEFEFE",
  },
  border: {
    main: "#D9D9D9",
  },
};

export default palette;
